import React from "react";
import GetStarted from "images/webflow/Get-started.png";
import { navigate } from "gatsby-link";

function Promotion() {
  return (
    <div class="promotion-section wf-section">
      <div class="promotion-container w-container">
        <div class="promotion-columns w-row">
          <div class="column-4 w-col w-col-5">
            <img src={GetStarted} loading="lazy" alt="" class="image" />
          </div>
          <div class="w-col w-col-7">
            <div class="promotion-title">
              Start your <span class="text-span-6">free</span> trial
            </div>
            <div class="promotion-text">
              Get ready for your exams with OSCEhub. Start your 7-day free trial
              now.
            </div>
            <a
              onClick={() => navigate("/registration")}
              class="button promotion w-button"
            >
              Register Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Promotion;
