import React, { useContext } from "react";
import { FirebaseContext } from "../components/Firebase";
import Header from "../components/Header";
import Promotion from "../components/Promotion";
import SEO from "../components/seo";
import SpecialtySelection from "../components/SpecialtySelection";

function Browse() {
  const { user } = useContext(FirebaseContext);
  return (
    <>
      <SEO
        title="Browse stations"
        description="OSCE stations for medical students. Practise with over 50 interactive cases on OSCEhub and smash your finals!"
      />
      <Header
        title="Browse OSCE Stations"
        subheading="OSCE stations covering a range of specialities & scenario types."
      />
      <div class="add-margin">
        <SpecialtySelection user={user} />
      </div>

      {!user && <Promotion />}
    </>
  );
}

export default Browse;
